import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
`;

export const Table = styled.table`
  overflow-x: auto;
  margin-left: 10px;
`;

export const Tr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;
`;

export const HeadTr = styled(Tr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const Th = styled.th`
  width: 105px;
  border: 1px solid #fff;
  background-color: #2f303a;
  color: #ffffff;
  font-weight: 700;
  padding: 1em;
  text-align: center;
`;

export const Td = styled.td`
  width: 127px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  position: relative;
  background-color: #eeeee8;
`;

export const Button = styled.button`
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 2px solid #eeee40;
  padding: 0.375rem 1.5rem;
  font-size: 0.8203125rem;
  line-height: 1.625;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #f5f7fa;
  background-color: #2f303a;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  display: block;
  transition: background-color 0.4s;
  cursor: pointer;
  width: 48%;

  &:hover,
  &:focus,
  &.active {
    background-color: green;
    border-color: #2f303a;
    transition: background-color 0.4s;
  }
`;

export const TrashButton = styled(Button)`
  &:hover,
  &:focus,
  &.active {
    background-color: red;
  }
`;

export const IconButton = styled(Link)`
  transition: background-color 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #27ae60;
  color: #ffffff;
  font-size: 16px;
  width: 50%;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #48e084;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #48e084;
    padding-right: 0.625rem;
    background-color: green;
    cursor: pointer;
    color: #ffffff;
    transition: background-color 0.4s;
  }
`;

export const MainContainer = styled.main`
padding: 50px 30px;
margin: 0 auto;
}
`;

export const PrimaryTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.33px;
`;

export const PrimaryText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 1.87;
  letter-spacing: 0.03em;
  font-weight: 700;
  margin-top: 10px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-item: center;
`;

export const Input = styled.input`
  padding: 10px;
  border-radius: 10px;
  width: 80%;
`;

export const Text = styled.p`
  margin: 0 0 5px 0;
  font-size: 16px;
  font-weight: 400;
`;
export const Select = styled.select`
  padding: 10px;
  border-radius: 10px;
  width: 100%;
`;

export const FormSelect = styled.select`
  padding: 10px;
  border-radius: 10px;
  width: 71%;
`;

export const Option = styled.option`
  padding: 10px;
  border-radius: 10px;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchContainer = styled.form`
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 3px solid #2f303a;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;

  &:focus {
    color: #00b4cc;
  }
`;

export const SearchButton = styled.button`
  width: 40px;
  height: 35px;
  background: #2f303a;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus,
  &.active {
    background-color: green;
    border: 2px solid #2f303a;
    transition: background-color 0.4s;
  }
`;

export const FilterSelect = styled.select`
  appearance: none;
  width: 62px;
  height: 34px;
  padding: 5px;
  padding-left: 5px;
  background-color: #2f303a;
  border: 2px solid #eeee40;
  font-size: 18px;
  margin-left: 10px;
  border-radius: 10px;
  color: #f5f7fa;

  cursor: pointer;
  text-align: center;

  &:hover,
  &:focus,
  &.active {
    background-color: green;
    border: 2px solid #2f303a;
    transition: background-color 0.4s;
  }
`;

export const FilterOption = styled.option`
  appearance: none;
  width: 100%;
  height: 10px;
  padding-left: 5px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 18px;
  color: #000;

  cursor: pointer;
`;

export const TrashIconButton = styled(IconButton)`
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #ff6659;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #ff6659;
    padding-right: 0.625rem;
    background-color: #c0392b;
    color: #ffffff;
  }
`;
export const RedTextColor = styled.span`
  color: red;
`;

export const BlueTextColor = styled.span`
  color: blue;
`;

export const GreenTextColor = styled.span`
  color: green;
`;

export const OrangeBackgroundPrimaryTitle = styled(PrimaryTitle)`
  background-color: darkgoldenrod;
`;

export const GreenBackgroundPrimaryTitle = styled(PrimaryTitle)`
  background-color: green;
`;

export const GlobalLink = styled.a`
text-decoration: none;
  word-wrap: break-word;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  color: #000000;
`