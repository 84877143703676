import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";

export const TopBarLogoutButton = styled.button`
  cursor: pointer;
  box-shadow: none;
  width: 50px;
  height: 50px;
  color: #3098d6;
  border: none;
  background-color: inherit;
`;

export const SharedLayoutContainer = styled.header`
  display: flex;
  min-height: 100px;
  background-color: #2f303a;
  padding: 15px 15px 0 15px;
`;
export const ImageContainer = styled.div`
  width: 198px;
  height: 66px;
`;
export const NavList = styled.nav`
  display: flex;
  flex-wrap: wrap;
  background-color: inherit;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  width: 90%;
  margin-left: 80px;
`;

export const NavigationLink = styled(NavLink)`
  color: #ffffff;
  transition: background-color 0.2s;
  display: block;
  text-decoration: none;
  background-color: inherit;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  &:hover,
  &:focus,
  &.active {
    background-color: #eeee40;
    color: #000000;
  }
`;

export const TopBarLogoutContainer = styled.div`
  width: 10%;
  margin-left: 80px;
`;

export const ExitDoor = styled(FaPowerOff)`
  color: #ffffff;

  &:hover,
  &:focus {
    color: #eeee40;
  }
`;

export const SecondaryRouteContainer = styled.ul`
  display: block;
  background-color: #000000;
  margin: 0px;
  padding: 0;
  list-style: none;
  position: absolute;
  width: 250px;
  z-index: 10;
`;

export const SecondaryItem = styled.li`
  color: #ffffff;
  width: 250px;
  transition: background-color 0.2s;
  display: block;
  padding: 0;
  text-decoration: none;
  background-color: inherit;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  &:hover,
  &:focus,
  &.active {
    background-color: #eeee40;
    color: #000000;
  }
`;

export const SecondaryNavigationLink = styled(NavigationLink)`
  width: 100%;
`;

export const SharedLayoutListItem = styled.li`
  padding: 0;
  width: 250px;
  font-size: 20px;
`;
