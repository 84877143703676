import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Home } from "./pages/Home/Home";
import { NotFound } from "./pages/NotFound/NotFound.jsx";
import LoginPage from "./pages/Login/Login";
import { UserList } from "./components/Users/UsersList/UserList.jsx";
import { UserItem } from "./components/Users/UsersItem/UsersItem.jsx";
import { UserPage } from "./pages/Users/User.jsx";
import { GlobalSettings } from "./pages/GlobalSettings/GlobalSettings.jsx";
import { fetchPermission } from "./api/settings.js";
import { OnlineUsers } from "./pages/OnlineUsers/OnlineUsers.jsx";
import { KnowledgeBaseItem } from "./components/KnowledgeBase/KnowledgeBaseItem/KnowledgeBaseItem.jsx";
import { KnowledgeBaseList } from "./components/KnowledgeBase/KnowledgeBaseList/KnowledgeBaseList.jsx";
import { KnowledgeBaseDetails } from "./components/KnowledgeBase/KnowledgeBaseDetails/KnowledgeBaseDetails.jsx";
import { ProductsChangeItem } from "./components/Products/ProductsModalItem/ProductsChangeItem.jsx";
import { ProductList } from "./components/Products/ProductsList/ProductsList.jsx";
import { ProductDetails } from "./components/Products/ProductDetails/ProductDetails.jsx";
import { Script } from "./pages/Script/Script.jsx";
import { Schedule } from "./pages/Schedule/Schedule.jsx";
import { ScheduleAllEmployees } from "./components/Schedule/ScheduleAllEmployees/ScheduleAllEmployees.jsx";
import { ScheduleMe } from "./components/Schedule/ScheduleMe/ScheduleMe.jsx";
import { OrderList } from "./components/Order/OrderList/OrderList.jsx";
import { PrimaryOrderList } from "./components/Order/PrimaryOrderList/PrimaryOrderList.jsx";
import { PrimaryContacts } from "./components/Contacts/PrimaryContacts.jsx";
import { SecondaryContacts } from "./components/Contacts/SecondaryContacts.jsx";
import { DailyStatistic } from "./components/Statistics/EmployeeStatistic/DailyStatistic.jsx";
import { SummaryByEmployee } from "./components/Statistics/EmployeeStatistic/SummaryByEmployee.jsx";
import { OverallSummary } from "./components/Statistics/EmployeeStatistic/OverallSummary.jsx";
import { ProductOrderStatistics } from "./components/Statistics/Product/ProductOrderStatistics.jsx";
import { ContactsStatistics } from "./components/Statistics/Contact/ContactsStatistics.jsx";
import { MyStatistics } from "./components/Statistics/EmployeeStatistic/MyStatistics.jsx";
import { BalanceMe } from "./components/Balance/BalanceMe/BalanceMe.jsx";
import { PrimaryBalanceList } from "./components/Balance/PrimaryBalanceList/PrimaryBalanceList.jsx";
import { SummaryBalance } from "./components/Balance/SummaryBalance/SummaryBalance.jsx";
import { SecondaryDailyStatistics } from "./components/Statistics/EmployeeStatistic/SecondaryDailyStatistics.jsx";
import { SecondarySummaryByEmployee } from "./components/Statistics/EmployeeStatistic/SecondarySummaryByEmployee.jsx";
import { SecondaryOverallSummary } from "./components/Statistics/EmployeeStatistic/SecondaryOverallSummary.jsx";

function App() {
  const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location?.pathname;

    const checkPermission = async () => {
      try {
        const response = await fetchPermission(path);

        if (!response?.hasAccessToRequestedRoute) {
          return navigate("/login");
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        if (Number(error?.response?.status) === 401) {
          toast.error(errorMessage);

          return navigate("/login");
        }
      }
    };

    useEffect(() => {
      checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/user",
      element: (
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "create",
          element: (
            <ProtectedRoute>
              <UserItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change/:id",
          element: (
            <ProtectedRoute>
              <UserItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "current-user",
          element: (
            <ProtectedRoute>
              <UserItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedRoute>
              <UserList />
            </ProtectedRoute>
          ),
        },
        {
          path: "online",
          element: (
            <ProtectedRoute>
              <OnlineUsers />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/knowledge-base",
      element: (
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "create",
          element: (
            <ProtectedRoute>
              <KnowledgeBaseItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change/:id",
          element: (
            <ProtectedRoute>
              <KnowledgeBaseItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "primary-list",
          element: (
            <ProtectedRoute>
              <KnowledgeBaseList />
            </ProtectedRoute>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedRoute>
              <KnowledgeBaseList />
            </ProtectedRoute>
          ),
        },
        {
          path: "primary-list/:id",
          element: (
            <ProtectedRoute>
              <KnowledgeBaseDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "list/:id",
          element: (
            <ProtectedRoute>
              <KnowledgeBaseDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/product",
      element: (
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "create",
          element: (
            <ProtectedRoute>
              <ProductsChangeItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change/:id",
          element: (
            <ProtectedRoute>
              <ProductsChangeItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedRoute>
              <ProductList />
            </ProtectedRoute>
          ),
        },
        {
          path: "list/:id",
          element: (
            <ProtectedRoute>
              <ProductDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "primary-list",
          element: (
            <ProtectedRoute>
              <ProductList />
            </ProtectedRoute>
          ),
        },
        {
          path: "primary-list/:id",
          element: (
            <ProtectedRoute>
              <ProductDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },    
    {
      path: "/schedule",
      element: (
        <ProtectedRoute>
          <Schedule />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "me",
          element: (
            <ProtectedRoute>
              <ScheduleMe />
            </ProtectedRoute>
          ),
        },
        {
          path: "primary-list",
          element: (
            <ProtectedRoute>
              <ScheduleAllEmployees />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/order",
      element: (
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "primary-list",
          element: (
            <ProtectedRoute>
              <PrimaryOrderList />
            </ProtectedRoute>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedRoute>
              <OrderList />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/balance",
      element: (
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "primary-list",
          element: (
            <ProtectedRoute>
              <PrimaryBalanceList />
            </ProtectedRoute>
          ),
        },
        {
          path: "overall-summary",
          element: (
            <ProtectedRoute>
              <SummaryBalance />
            </ProtectedRoute>
          ),
        },
        {
          path: "me",
          element: (
            <ProtectedRoute>
              <BalanceMe />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/statistics",
      element: (
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "daily",
          element: (
            <ProtectedRoute>
              <DailyStatistic />
            </ProtectedRoute>
          ),
        },
        {
          path: "secondary-daily",
          element: (
            <ProtectedRoute>
              <SecondaryDailyStatistics />
            </ProtectedRoute>
          ),
        },
        {
          path: "summary-by-employee",
          element: (
            <ProtectedRoute>
              <SummaryByEmployee />
            </ProtectedRoute>
          ),
        },
        {
          path: "secondary-summary-by-employee",
          element: (
            <ProtectedRoute>
              <SecondarySummaryByEmployee />
            </ProtectedRoute>
          ),
        },
        {
          path: "overall-summary",
          element: (
            <ProtectedRoute>
              <OverallSummary />
            </ProtectedRoute>
          ),
        },
        {
          path: "secondary-overall-summary",
          element: (
            <ProtectedRoute>
              <SecondaryOverallSummary />
            </ProtectedRoute>
          ),
        },
        {
          path: "product",
          element: (
            <ProtectedRoute>
              <ProductOrderStatistics />
            </ProtectedRoute>
          ),
        },
        {
          path: "contact",
          element: (
            <ProtectedRoute>
              <ContactsStatistics />
            </ProtectedRoute>
          ),
        },
        {
          path: "me",
          element: (
            <ProtectedRoute>
              <MyStatistics />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/contacts",
      element: (
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "primary-list",
          element: (
            <ProtectedRoute>
              <PrimaryContacts />
            </ProtectedRoute>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedRoute>
              <SecondaryContacts />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/script",
      element: (
        <ProtectedRoute>
          <Script />
        </ProtectedRoute>
      ),
    },
    {
      path: "/crm-settings",
      element: (
        <ProtectedRoute>
          <GlobalSettings />
        </ProtectedRoute>
      ),
    },
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <NotFound />
        </ProtectedRoute>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
