import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { Outlet } from "react-router-dom";

export const Home = () => {
  return (
    <div>
      <SharedLayout />
      <div>Нажаль поки що на цій сторінці нічого немає</div>
      <Outlet />
    </div>
  );
};
