import axios from "axios";
import { decryptString } from "../services/decryptMiddleware";

export const makeRequestSQL = axios.create({
  baseURL: 'https://www.dcm-crm.online/api',
});

// export const makeRequestSQL = axios.create({
//   baseURL: "http://localhost:3002/api",
// });

makeRequestSQL.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      const decryptedToken = await decryptString(token);
      config.headers.Authorization = `Bearer ${decryptedToken}`;
    }
    return config;
  },
  (error) => {
    return console.log("error text");
  }
);

export const clearAuthHeader = () => {
  makeRequestSQL.defaults.headers.common.Authorization = "";
};
