import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";


export const addNewAmount = async (data) => {
    try {
      const response = await makeRequestSQL.post("/balance", data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchBalanceMe = async ({
    page,
    limit,
    startDate,
    endDate,
  }) => {
    try {
      const response = await makeRequestSQL.get("/balance/me", {
        params: {
          page,
          limit,
          startDate,
          endDate,
        },
      });
      const decryptedData = await decryptMiddleware(response.data);
  
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchBalanceAll = async ({
    page,
    limit,
    employee,
    startDate,
    endDate,
  }) => {
    try {
      const response = await makeRequestSQL.get("/balance/primary-list", {
        params: {
          page,
          limit,
          startDate,
          endDate,
        },
      });
      const decryptedData = await decryptMiddleware(response.data);
  
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const updateBalance = async (data) => {
    try {
      const response = await makeRequestSQL.put("/balance", data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };