import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { MyEditor } from "../../MyEditor/MyEditor";
import { toast } from "react-toastify";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import {
  createProduct,
  updateProduct,
  fetchProduct,
  deleteProduct,
  fetchProductCategory,
} from "../../../api/product";
import {
  Input,
  ButtonContainer,
  Button,
  TrashButton,
  FormSelect,
  Option,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { useQuery } from "react-query";

export const ProductsChangeItem = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [product, setProduct] = useState({
    id: "",
    title: "",
    description: "",
    price: "",
    category_id: "",
    image_url: "",
    video_url: "",
    is_available: "",
  });

  const { data: productCategory } = useQuery(["productCategory"], () =>
    fetchProductCategory()
  );

  const path = location?.pathname;

  const description = useRef("");

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");

      if (parts?.length >= 3) {
        const getId = parts[3];
        setSelectedId(getId);
      }

      if (parts?.length > 2) {
        const checkUpdate = `/${parts[2]}` === "/change";
        const checkCreate = `/${parts[2]}` === "/create";

        if (checkUpdate) {
          setIsUpdate(true);
        }

        if (checkCreate) {
          setIsCreate(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    const fetchProductData = async () => {
      if (isCreate) {
        return;
      }

      if (isUpdate) {
        const response = await fetchProduct({ id: selectedId });

        if (response?.data?.length > 0) {
          const fetchedProduct = response?.data;

          setProduct({
            ...fetchedProduct[0],
          });
          description.current = fetchedProduct[0]?.description || "";
        }
      }
    };

    fetchProductData();
  }, [location, isCreate, selectedId, isUpdate]);

  const maxLength = 250;

  const handlePreSubmit = () => {
    const newProduct = {
      id: product?.id || "",
      title: product?.title || "",
      description: description.current || "",
      price: product?.price || 0,
      category_id: product?.category_id || "",
      image_url: product?.image_url || "",
      video_url: product?.video_url || "",
      is_available: product?.is_available || "",
    };

    handleSubmit(newProduct);
  };

  const handleSubmit = async (formData) => {
    if (isCreate) {
      const response = await createProduct(formData);

      if (response?.status === 201) {
        toast.success(response?.data?.message);
        return navigate("/product/primary-list");
      }
    }

    if (isUpdate) {
      const response = await updateProduct(formData);

      if (response?.status === 201) {
        toast.success(response?.data?.message);
        return navigate(`/product/primary-list/${product?.id}`);
      }
    }
  };

  const handleCancelClick = () => {
    return navigate("/product/primary-list");
  };

  const handleDelete = async (id) => {
    const response = await deleteProduct(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return navigate("/product/primary-list");
    }
  };

  return (
    <Main>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Input
            style={{ width: "70%", marginRight: "10px" }}
            value={product?.title}
            type="text"
            onChange={(e) => {
              const updatedProduct = {
                ...product,
                title: e.target.value,
              };
              if (updatedProduct?.title?.length <= maxLength) {
                setProduct(updatedProduct);
              }
            }}
            placeholder="Введіть назву товару"
            maxLength={maxLength}
            required
          />
          <p style={{ margin: "0" }}>
            Залишилося символів: {maxLength - product?.title?.length}/
            {maxLength}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Input
            style={{ width: "70%", marginRight: "10px" }}
            value={product?.price}
            type="number"
            onChange={(e) => {
              const updatedProduct = {
                ...product,
                price: e.target.value,
              };
              setProduct(updatedProduct);
            }}
            placeholder="Введіть ціну товару"
            maxLength={maxLength}
            required
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Input
            style={{ width: "70%", marginRight: "10px" }}
            value={product?.image_url}
            type="text"
            onChange={(e) => {
              const updatedProduct = {
                ...product,
                image_url: e.target.value,
              };
              setProduct(updatedProduct);
            }}
            placeholder="Введіть посиланя на зображеня"
            maxLength={maxLength}
            required
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Input
            style={{ width: "70%", marginRight: "10px" }}
            value={product?.video_url}
            type="text"
            onChange={(e) => {
              const updatedProduct = {
                ...product,
                video_url: e.target.value,
              };
              setProduct(updatedProduct);
            }}
            placeholder="Введіть посиланя на відео"
            maxLength={maxLength}
            required
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <FormSelect
            value={product?.category_id}
            onChange={(e) => {
              const updatedProduct = {
                ...product,
                category_id: e.target.value,
              };
              setProduct(updatedProduct);
            }}
            style={{ marginRight: "5px" }}
          >
            <Option value="">Виберіть категорію</Option>
            {productCategory?.map((category) => (
              <Option key={category?.id} value={category?.id}>
                {category?.title}
              </Option>
            ))}
          </FormSelect>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <FormSelect
            value={product?.is_available}
            onChange={(e) => {
              const updatedProduct = {
                ...product,
                is_available: e.target.value,
              };
              setProduct(updatedProduct);
            }}
            style={{ marginRight: "5px" }}
          >
            <Option>Оберіть наявність товару</Option>
            <Option key={1} value="true">
              Є в наявності
            </Option>
            <Option key={0} value="false">
              Немає в наявності
            </Option>
          </FormSelect>
        </div>

        <div style={{ width: "100%", minHeight: "300px" }}>
          <MyEditor
            value={description.current || ""}
            onChange={(newContent) => (description.current = newContent)}
          />
        </div>
      </div>

      {isCreate && (
        <ButtonContainer>
          <Button onClick={() => handlePreSubmit()}>Зберегти</Button>
          <TrashButton onClick={() => handleCancelClick()}>
            Скасувати
          </TrashButton>
        </ButtonContainer>
      )}
      {isUpdate && (
        <ButtonContainer>
          <Button onClick={() => handlePreSubmit()}>Внести зміни</Button>
          <TrashButton
            onClick={() => {
              handleDelete(product.id);
            }}
          >
            Видалити
          </TrashButton>
        </ButtonContainer>
      )}
    </Main>
  );
};
