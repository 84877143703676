import { SharedLayout } from '../../components/SharedLayout/SharedLayout';

export const NotFound = () => {
  return (
    <div>
      <SharedLayout />
      <div>Вибачте, ми не змогли знайти цю сторінку :(</div>
    </div>
  );
};
