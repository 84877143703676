import React, { useContext, useState } from "react";
import {
  TopBarLogoutContainer,
  TopBarLogoutButton,
  SharedLayoutContainer,
  ImageContainer,
  NavList,
  NavigationLink,
  ExitDoor,
  SecondaryRouteContainer,
  SecondaryItem,
  SecondaryNavigationLink,
  SharedLayoutListItem,
} from "./SharedLayout.styled";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { UserContext } from "../Context/UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logoutUser } from "../../api/users";
import logo from "../../images/logo.png";
import { fetchPermission } from "../../api/settings";

export const SharedLayout = () => {
  const navigate = useNavigate();
  const { clearToken } = useContext(UserContext);
  const [showSecondary, setShowSecondary] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState("");

  const handleLogout = async () => {
    const response = await logoutUser();

    if (Number(response?.status) === 201) {
      clearToken("");
      navigate("/login");
    }
  };

  const { data } = useQuery("menuData", fetchPermission);

  const handleMainRouteHover = (mainRoute) => {
    const foundRoute = data.routes.find(
      (route) => route.mainRoute.route_part === mainRoute
    );

    if (foundRoute && foundRoute?.secondaryRoutes?.length > 1) {
      setShowSecondary(true);
      setSelectedRoute(mainRoute);
    } else {
      setShowSecondary(false);
    }
  };

  const handleMainRouteBlur = (event) => {
    if (
      event?.relatedTarget &&
      event?.currentTarget?.contains(event?.relatedTarget)
    ) {
      return;
    }
    setShowSecondary(false);
  };

  return (
    <SharedLayoutContainer>
      <ImageContainer>
        <a href="https://www.dcm-crm.com.ua/">
          <img src={logo} width="100%" height="100%" alt="dcm" />
        </a>
      </ImageContainer>

      <NavList>
        {data?.routes?.map((route) => (
          <SharedLayoutListItem
            onMouseEnter={() =>
              handleMainRouteHover(route.mainRoute.route_part)
            }
            onMouseLeave={handleMainRouteBlur}
            onFocus={() => handleMainRouteHover(route.mainRoute.route_part)}
            onBlur={handleMainRouteBlur}
            key={route.mainRoute.id}
          >
            <NavigationLink
              to={
                route?.secondaryRoutes?.length === 1
                  ? `${route.mainRoute.route_part}${route?.secondaryRoutes[0]?.route_part}`
                  : route.mainRoute.route_part
              }
            >
              {route?.secondaryRoutes?.length === 1
                ? route?.secondaryRoutes[0]?.name
                : route.mainRoute.name}
            </NavigationLink>
            {showSecondary && selectedRoute === route.mainRoute.route_part && (
              <SecondaryRouteContainer>
                {route?.secondaryRoutes?.map((secondaryRoute) => (
                  <SecondaryItem key={secondaryRoute.id}>
                    <SecondaryNavigationLink
                      to={`${route.mainRoute.route_part}${secondaryRoute.route_part}`}
                    >
                      {secondaryRoute.name}
                    </SecondaryNavigationLink>
                  </SecondaryItem>
                ))}
              </SecondaryRouteContainer>
            )}
          </SharedLayoutListItem>
        ))}
      </NavList>

      <TopBarLogoutContainer>
        <TopBarLogoutButton onClick={handleLogout}>
          <ExitDoor size="36px" />
        </TopBarLogoutButton>
      </TopBarLogoutContainer>
      <ToastContainer autoClose={4000} />
    </SharedLayoutContainer>
  );
};
