import { MainContainer } from "./GlobalStyles.styled";
import PropTypes from "prop-types";

export const Main = ({ children }) => {
  return <MainContainer>{children}</MainContainer>;
};

Main.defaultProps = {
  children: null,
};

Main.propTypes = {
  children: PropTypes.node,
};
